<template>
  <div>
    <v-card
      :to="{
        name: 'accounting-time-sheets-details',
        params: { itemId: timeSheet.meta.id },
      }"
    >
      <v-card-title class="subtitle-1 font-weight-medium mt-1 mb-1">
        <span v-if="timeSheet.title">{{ timeSheet.title }}</span>
        <span v-else class="grey--text">Bericht ohne Titel</span>
        <v-spacer></v-spacer>
        <time-sheet-status-chip :status="timeSheet.status" small>
        </time-sheet-status-chip>
      </v-card-title>
      <v-card-subtitle class="fonrt-weight-regular">
        <p style="line-height: 1.6" class="mb-0">
          <v-icon small left>mdi-circle</v-icon>{{ timeSheet.title }}
          <br />
          <v-icon small left>mdi-clock-start</v-icon
          ><span>
            Erstellt am
            {{ timestampConverter(timeSheet.meta.createdAt) }}</span
          >
        </p>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
import TimeSheetStatusChip from "@/components/accounting/time-sheets/TimeSheetStatusChip.vue";
export default {
  name: "time-sheet-card",
  props: {
    timeSheet: {
      type: Object,
      required: true,
    },
  },
  components: {
    TimeSheetStatusChip,
  },
  data() {
    return {};
  },

  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
