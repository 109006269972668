var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[(!_vm.helpers.dataIsLoading)?_c('v-card-title',{staticClass:"px-0",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''},[_vm._v("Meine Dienstberichte"),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","outlined":"","small":"","to":{
          name: 'duty-management-reports',
        }}},[_vm._v("Alle anzeigen")])],1):_vm._e(),_c('div',[(_vm.helpers.dataIsLoading)?_c('v-skeleton-loader',{attrs:{"elevation":"2","type":"card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"}}):_c('v-data-iterator',{attrs:{"items":_vm.reports,"no-data-text":"Keine Dienstberichte vorhanden","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticClass:"match-height"},_vm._l((props.items),function(report){return _c('v-col',{key:report.meta.id,attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-card',{attrs:{"to":{
                  name: 'duty-management-reports-details',
                  params: { itemId: report.meta.id },
                }}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium mt-1 mb-1"},[(report.title)?_c('span',[_vm._v(_vm._s(report.title))]):_c('span',{staticClass:"grey--text"},[_vm._v("Bericht ohne Titel")]),_c('v-spacer'),_c('report-status-chip',{attrs:{"status":report.status,"small":""}})],1),_c('v-card-subtitle',{staticClass:"fonrt-weight-regular"},[_c('p',{staticClass:"mb-0",staticStyle:{"line-height":"1.6"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-circle")]),_vm._v(_vm._s(report.type.title)+" "),_c('br'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-clock-start")]),_c('span',{class:!report.duration.start.timestamp ? 'grey--text' : ''},[_vm._v(" "+_vm._s(report.duration.start.timestamp ? _vm.timestampConverter( report.duration.start.timestamp ) : "Nicht festgelegt"))]),_c('br'),_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-clock-end")]),_c('span',{class:!report.duration.end.timestamp ? 'grey--text' : ''},[_vm._v(" "+_vm._s(report.duration.end.timestamp ? _vm.timestampConverter(report.duration.end.timestamp) : report.duration.end.estimatedTimestamp ? "Erwartet:" : "")+" "),(
                          !report.duration.end.timestamp &&
                          report.duration.end.estimatedTimestamp
                        )?_c('br'):_vm._e(),_vm._v(" "+_vm._s(report.duration.end.estimatedTimestamp ? _vm.timestampConverter( report.duration.end.estimatedTimestamp ) : !report.duration.end.timestamp ? "Nicht festgelegt" : ""))])],1)])],1)],1)}),1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }