<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title
        v-if="!helpers.dataIsLoading"
        class="px-0"
        :class="
          $vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''
        "
        >Meine Abrechnungszettel<v-spacer></v-spacer>
        <v-btn
          text
          outlined
          small
          :to="{
            name: 'accounting-payslips',
          }"
          >Alle anzeigen</v-btn
        >
      </v-card-title>
      <div>
        <v-skeleton-loader
          v-if="helpers.dataIsLoading"
          elevation="2"
          type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
        ></v-skeleton-loader>
        <v-data-iterator
          v-else
          :items="payslips"
          no-data-text="Keine Abrechnungszettel vorhanden"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row class="match-height">
              <v-col
                v-for="payslip in props.items"
                :key="payslip.meta.id"
                cols="12"
                sm="4"
                md="4"
              >
                <payslip-card :payslip="payslip"></payslip-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYSLIPS } from "@/store/action-types.js";
// import PayslipCard from "@/components/accounting/payslips/PayslipCard.vue";
export default {
  name: "self-service-open-payslips",
  props: {},
  components: {
    // PayslipCard,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
    };
  },
  computed: {
    payslips() {
      return this.$store.state.accounting.payslips;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYSLIPS}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          queryIsFiltered: true,
          onlyOwn: true,
        },
        paginationLimit: 3,
      });
    },
  },
};
</script>
