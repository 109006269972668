<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title
        v-if="!helpers.dataIsLoading"
        class="px-0"
        :class="
          $vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''
        "
        >Meine Dienstberichte<v-spacer></v-spacer>
        <v-btn
          text
          outlined
          small
          :to="{
            name: 'duty-management-reports',
          }"
          >Alle anzeigen</v-btn
        >
      </v-card-title>
      <div>
        <v-skeleton-loader
          v-if="helpers.dataIsLoading"
          elevation="2"
          type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
        ></v-skeleton-loader>
        <v-data-iterator
          v-else
          :items="reports"
          no-data-text="Keine Dienstberichte vorhanden"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:default="props">
            <!-- <v-slide-group show-arrows class="px-0 mx-0">
              <v-slide-item v-for="task in props.items" :key="task.id">
                <v-col cols="5" sm="6" md="4">
                  <v-card>
                    <v-list-item link dense :to="task.route">
                      <v-list-item-content>
                        <v-list-item-title
                          class="subtitle-1 font-weight-medium mb-1"
                        >
                          <span>{{ task.title }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <p style="line-height: 1.6" class="mb-0">
                            <v-icon small left>mdi-circle</v-icon
                            >{{ task.type.title }}
                            <br />
                            <v-icon small left>mdi-circle</v-icon
                            >{{ task.status }}
                            <br />
                            <v-icon small left>mdi-sand-timer</v-icon
                            >{{ task.dueDate.timestamp }}<br />
                          </p>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-slide-item>
            </v-slide-group> -->
            <v-row class="match-height">
              <v-col
                v-for="report in props.items"
                :key="report.meta.id"
                cols="12"
                sm="4"
                md="4"
              >
                <v-card
                  :to="{
                    name: 'duty-management-reports-details',
                    params: { itemId: report.meta.id },
                  }"
                >
                  <v-card-title class="subtitle-1 font-weight-medium mt-1 mb-1">
                    <span v-if="report.title">{{ report.title }}</span>
                    <span v-else class="grey--text">Bericht ohne Titel</span>
                    <v-spacer></v-spacer>
                    <report-status-chip :status="report.status" small>
                    </report-status-chip>
                  </v-card-title>
                  <v-card-subtitle class="fonrt-weight-regular">
                    <p style="line-height: 1.6" class="mb-0">
                      <v-icon small left>mdi-circle</v-icon
                      >{{ report.type.title }}
                      <br />
                      <v-icon small left>mdi-clock-start</v-icon
                      ><span
                        :class="
                          !report.duration.start.timestamp ? 'grey--text' : ''
                        "
                      >
                        {{
                          report.duration.start.timestamp
                            ? timestampConverter(
                                report.duration.start.timestamp
                              )
                            : "Nicht festgelegt"
                        }}</span
                      >
                      <br />
                      <v-icon small left>mdi-clock-end</v-icon
                      ><span
                        :class="
                          !report.duration.end.timestamp ? 'grey--text' : ''
                        "
                      >
                        {{
                          report.duration.end.timestamp
                            ? timestampConverter(report.duration.end.timestamp)
                            : report.duration.end.estimatedTimestamp
                            ? "Erwartet:"
                            : ""
                        }}
                        <br
                          v-if="
                            !report.duration.end.timestamp &&
                            report.duration.end.estimatedTimestamp
                          "
                        />
                        {{
                          report.duration.end.estimatedTimestamp
                            ? timestampConverter(
                                report.duration.end.estimatedTimestamp
                              )
                            : !report.duration.end.timestamp
                            ? "Nicht festgelegt"
                            : ""
                        }}</span
                      >
                    </p>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </div>
    </v-card>
  </div>
</template>

<script>
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORTS } from "@/store/action-types.js";
// import DutyTypeChip from "@/components/duty-management/DutyTypeChip.vue";
import ReportStatusChip from "@/components/duty-management/ReportStatusChip.vue";
export default {
  name: "self-service-open-duty-reports",
  props: {},
  components: {
    // DutyTypeChip,
    ReportStatusChip,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
    };
  },
  computed: {
    reports() {
      return this.$store.state.dutyManagement.reports;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORTS}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          queryIsFiltered: true,
          onlyOwn: true,
        },
        paginationLimit: 3,
      });
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
