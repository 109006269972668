<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title
        v-if="!helpers.dataIsLoading"
        class="px-0"
        :class="
          $vuetify.breakpoint.smAndDown ? 'subtitle-1 font-weight-medium' : ''
        "
        >Meine Abwesenheiten<v-spacer></v-spacer>
        <v-btn
          text
          outlined
          small
          :to="{
            name: 'availability-leaves',
          }"
          >Alle anzeigen</v-btn
        >
      </v-card-title>
      <div>
        <v-skeleton-loader
          v-if="helpers.dataIsLoading"
          elevation="2"
          type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
        ></v-skeleton-loader>
        <v-data-iterator
          v-else
          :items="leavesMatched"
          no-data-text="Keine Abwesenheiten (aktiv/bevorstehend) vorgemerkt"
          sort-by="duration.start.timestamp"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row class="match-height">
              <v-col
                v-for="leave in props.items"
                :key="leave.meta.id"
                cols="12"
                sm="4"
                md="4"
              >
                <v-card
                  :to="{
                    name: 'availability-leaves-details',
                    params: { itemId: leave.meta.id },
                  }"
                >
                  <v-card-title class="subtitle-1 font-weight-medium mt-1 pb-2">
                    <leave-reason-chip :reason="leave.reason" small>
                    </leave-reason-chip>
                    <v-spacer></v-spacer>
                    <leave-status-chip
                      small
                      :status="leave.status"
                    ></leave-status-chip>
                  </v-card-title>
                  <v-card-subtitle class="font-weight-regular">
                    <p style="line-height: 1.6" class="mb-0">
                      <v-spacer></v-spacer>
                      <br />
                      <v-icon small left>mdi-clock-start</v-icon>
                      <span>{{
                        timestampConverter(leave.duration.start.timestamp)
                      }}</span>
                      <br />
                      <v-icon small left>mdi-clock-end</v-icon>
                      <span class="mr-1">{{
                        timestampConverter(leave.duration.end.timestamp)
                      }}</span
                      ><v-chip
                        v-if="leave.duration.end.isOpen"
                        x-small
                        class="font-weight-medium red--text text--darken-4"
                        color="red lighten-4"
                        >Ende offen</v-chip
                      >
                    </p>
                    <date-progress-bar
                      class="mt-2"
                      :start="leave.duration.start.timestamp"
                      :end="leave.duration.end.timestamp"
                      :color="`${getStatusColor(leave.status)} lighten-4`"
                    ></date-progress-bar>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </div>
    </v-card>
  </div>
</template>

<script>
import { AVAILABILITY } from "@/store/modules.js";
import { GET_LEAVES } from "@/store/action-types.js";
import DateProgressBar from "@/components/_systemwide/DateProgressBar.vue";
import LeaveStatusChip from "@/components/availability/leaves/LeaveStatusChip.vue";
import LeaveReasonChip from "@/components/availability/leaves/LeaveReasonChip.vue";
export default {
  name: "self-service-open-leaves",
  props: {},
  components: {
    DateProgressBar,
    LeaveStatusChip,
    LeaveReasonChip,
  },
  data() {
    return {
      helpers: {
        dataIsLoading: false,
      },
    };
  },
  computed: {
    leaves() {
      return this.$store.state.availability.leaves;
    },
    leavesMatched() {
      return this.leaves.map((leave) => ({
        ...leave,
        status: this.getLeaveStatus(
          leave.duration.start.timestamp.toDate(),
          leave.duration.end.timestamp.toDate()
        ),
      }));
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${AVAILABILITY}${GET_LEAVES}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          queryIsFiltered: true,
          onlyOwn: true,
        },
        paginationLimit: 3,
      });
    },
    getLeaveStatus(start, end) {
      const now = new Date().getTime();

      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();

      if (startTime > now) {
        return "upcoming";
      } else if (startTime <= now && endTime >= now) {
        // Wenn Startzeit jetzt oder in Vergangenheit und Endzeit noch in der Zukunft liegt, dann "active"
        return "active";
      } else if (endTime < now) {
        // Wenn Startzeit und Endzeit in der Vergangenheit liegen, dann "finished"
        return "finished";
      } else {
        return "error";
      }
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case "active":
          return "red";
        case "upcoming":
          return "orange";
        case "finished":
          return "green";
        default:
          return "grey";
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
